import { generateClientErrorMessage } from "../utils/generateClientErrorMessage"
import { api } from "../config/api"

const login = async (user) => {
    try {

        const res = await api.post('/login', user)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        api.defaults.headers.Authorization = res.data.token
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('id_user', res.data.id_user)


        return { success: true }


    } catch (error) {

        return { success: false, message: generateClientErrorMessage(error) }
    }
}


const createUser = async (user) => {
    try {

        const res = await api.post('/createUser', user)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true }


    } catch (error) {

        return { success: false, message: generateClientErrorMessage(error) }
    }
}


const checkAuth = async () => {
    try {

        const res = await api.get('/checkAuth')

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true }


    } catch (error) {
        console.error(error)
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

export default {
    login,
    createUser,
    checkAuth
}