import React from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CButton,
} from "@coreui/react-pro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "../../contexts/AlertContext";
import postServices from '../../services/post'
import { useState } from "react";

function DeletePostModal({ visible, hideModal, postId }) {

  const { showAlert } = useAlert()

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: () => postServices.deletePost(postId),
    onSuccess: (response) => {
      if(response.success){
        queryClient.invalidateQueries(['carrosel'])
        hideModal()
      }
      showAlert(response.message, response.success ? 'success' : 'error')
    },
    onError: (response) => showAlert(response.message, 'error')
  })

  return (
    <CModal 
      id="modal" 
      size="xl" 
      visible={visible}
      backdrop={"static"}
      onClose={hideModal}
      >
      <CModalHeader>
        <CModalTitle id="OptionalSizesExample1"></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="title-modal-delete">Confirmação de exclusão</div>
        <div className="mt-5">
          <h3 className="delete">Deseja excluir esta publicação?</h3>
          <p className="delete">
            Após excluir esta publicação, não será <br></br> possível desfazer a
            exclusão.
          </p>
        </div>
        <div className="d-flex gap-3">
          <CButton className="button-modal-cancel mt-5 mb-3" disabled={isPending} onClick={hideModal}>Cancelar</CButton>
          <CButton className="button-modal mt-5 mb-3" onClick={mutate} disabled={isPending}>
            {isPending ? 'Excluindo...' : 'Confirmar'}
          </CButton>
        </div>
      </CModalBody>
    </CModal>
  );
}

export default DeletePostModal;
