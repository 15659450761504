import React from "react";
import logo from "../assets/icon/logo.png";

function Footer() {
  return (
    <div className="footer-bg">
      <div className="footer">
        <div className="logo">
          <img src={logo} alt="Logotipo da empresa" width={"250px"} />
          <div className="line"></div>
        </div>
        <div className="address">
          <p>R. Francisco Reis, 1402 - Cordeiros, Itajaí - SC, 88311-750</p>
        </div>
        <div className="rights">
          <p>
            © 2024 - GH Solucionador Logístico | Todos os Direitos Reservados{" "}
          </p>
          <p>
            A product develop by <a href="https://singlec.com.br/">Singlec</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
