import React, { useState, useEffect } from "react";
import {
  CNavbar,
  CNavbarBrand,
  CContainer,
  CNavbarToggler,
  COffcanvas,
  COffcanvasBody,
  CNavbarNav,
  CNavItem,
  CButton,
  CNavLink,
  CTooltip,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
} from "@coreui/react-pro";

import VisibilitySensor from "react-visibility-sensor";
import logo from "../assets/icon/logo.png";
import ModalRegister from "./post/CreatePostModal.jsx";

import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const [isVisible, setIsVisible] = useState(false);
  const [visibleRegister, setVisibleRegister] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate()

  const handleLogout = () => {
    navigate('/login');
    localStorage.clear()
  }

  return (
    <>
      <CNavbar className={`py-4 ${scrolled ? "scrolled" : ""}`} expand="xxl">
        <div className="nav-container">
          <CContainer fluid>
            <CNavbarBrand className={`navLogo2 ${isVisible ? "visible" : ""}`}>
              <img src={logo} alt="logo" width={160} />
            </CNavbarBrand>
            <CNavbarToggler
              aria-controls="offcanvasNavbar2"
              aria-label="Toggle navigation"
              onClick={() => setVisible(!visible)}
            />
            <COffcanvas
              id="offcanvasNavbar2"
              placement="end"
              portal={false}
              visible={visible}
              onHide={() => setVisible(false)}
            >
              <COffcanvasHeader>
                <COffcanvasTitle></COffcanvasTitle>
                <CCloseButton
                  className="text-reset"
                  onClick={() => setVisible(false)}
                />
              </COffcanvasHeader>
              <COffcanvasBody>
                <div className="flex-content-nav">
                  <CNavbarNav>
                    <CNavItem
                      className={`px-1 blogLink ${isVisible ? "visible" : ""}`}
                    >
                      <CNavLink
                        href="https://www.ghtransporte.com.br/"
                        target="_blank"
                        active
                      >
                        Acessar site
                      </CNavLink>
                    </CNavItem>
                  </CNavbarNav>
                  <CNavbarBrand
                    className={`navLogo ${isVisible ? "visible" : ""}`}
                  >
                    <img src={logo} alt="logo" width={200} />
                  </CNavbarBrand>
                  <CNavbarNav>
                    <CNavItem className="flex-button-nav">
                      <CNavLink
                        href="#"
                        className={`px-0 ${isVisible ? "visible" : ""}`}
                      >
                        <CButton
                          type="button"
                          variant="outline"
                          className="clientLink"
                          onClick={() => setVisibleRegister(true)}
                        >
                          Publicar novo post
                        </CButton>
                      </CNavLink>
                    </CNavItem>
                    <button style={{background: 'none'}} onClick={handleLogout} className="aling-sair">
                      <CTooltip content="Sair" placement="bottom">
                        <CIcon
                          icon={icon.cilPowerStandby}
                          className="mt-1 "
                          width={25}
                          style={{ "--ci-primary-color": "#093390" }}
                        />
                      </CTooltip>
                    </button>
                  </CNavbarNav>
                </div>
              </COffcanvasBody>
            </COffcanvas>
          </CContainer>
        </div>
      </CNavbar>

      {visibleRegister && (
        <ModalRegister
          visible={visibleRegister}
          closeModal={() => setVisibleRegister(false)}
        />
      )}
    </>
  );
}

export default Navbar;
