import React, { useState } from "react";
import { CButton, CForm, CFormInput, CFormLabel } from "@coreui/react-pro";
import img from "../../assets/icon/logo.png";
import img1 from "../../assets/img/blog/welcome-bg.png";
import userServices from "../../services/user";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../contexts/AlertContext";

function LoginBlog() {
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();

  const defaultUserData = {
    email: "",
    password: "",
  };

  const [user, setUser] = useState(defaultUserData);
  const navigate = useNavigate();

  async function login() {
    setIsLoading(true);

    const res = await userServices.login(user);

    if (!res.success) {
      showAlert(res.message, "error");
      setIsLoading(false);
      return;
    }

    navigate("/");
    setIsLoading(false);
  }

  return (
    <>
      <div id="login-blog">
        <div className="align">
          <div className="img">
            <img src={img} alt="" width={350} />
            <div className="identifier">
              <p>
                GH Solucionador Logístico © 2024 | A Software develop by{" "}
                <a
                  href="https://singlec.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Singlec
                </a>
              </p>
            </div>
          </div>
          <CForm>
            <div>
              <h1 className="mb-2">Realizar login</h1>
              <p className="mb-4">
                Ao acessar nossos sistemas, saiba que sua atividade é rastreada
                e seus dados são protegidos por criptografia, conforme exigido
                pela Lei Geral de Proteção de Dados Pessoais (LGPD) para sua
                segurança.
              </p>
              <div className="align-form">
                <div>
                  <CFormLabel>E-mail:</CFormLabel>
                  <CFormInput
                    type="email"
                    className="me-2"
                    placeholder=""
                    value={user.email}
                    onChange={(e) =>
                      setUser((prev) => ({ ...prev, email: e.target.value }))
                    }
                  ></CFormInput>
                </div>
                <div>
                  <CFormLabel className="mt-4">Senha:</CFormLabel>
                  <CFormInput
                    type="password"
                    className="me-2"
                    placeholder=""
                    value={user.password}
                    onChange={(e) =>
                      setUser((prev) => ({ ...prev, password: e.target.value }))
                    }
                  ></CFormInput>
                </div>
                <CButton
                  className="mt-4"
                  type="button"
                  disabled={isLoading}
                  onClick={login}
                >
                  {isLoading ? "Carregando.." : "Entrar"}
                </CButton>
              </div>
            </div>
          </CForm>
          <img className="img1" src={img1} alt="" />
        </div>
      </div>
    </>
  );
}

export default LoginBlog;
