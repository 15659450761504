import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import SwiperCard from "../blog/components/SwiperCard";
import useRedirectAfterUnhautorizedRequest from "../../hooks/useRedirectAfterUnhautorizedRequest";
import TableNewsletter from "../../components/table/TableNewlatter";
import { HomeProvider } from "./contexts/HomeContext";
import ModalDelete from "../../components/modal/ModalDelete";

function Blog() {
  useRedirectAfterUnhautorizedRequest();

  return (
    <HomeProvider>
      <Navbar />
      <SwiperCard />
      <TableNewsletter />
      <Footer />
      <ModalDelete />
    </HomeProvider>
  );
}

export default Blog;
