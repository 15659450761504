import React from "react";
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCardImage,
} from "@coreui/react-pro";

function SectionVisiblePubli() {
  return (
    <div id="section-visible-publi">
      <CCard>
        <CCardBody className="container-sm">
          <CCardText className="align-item-title">
            <small className="text-medium-emphasis">
              4 de jan. de 2022 2 min
            </small>
            <small className="text-medium-emphasis">
              Atualizado: 10 de jan de 2022
            </small>
          </CCardText>
          <CCardTitle>
            Terceirização da armazenagem: como torná-la estratégica para a sua
            empresa
          </CCardTitle>
          <CCardText>
            A falta de espaço para estocagem é uma condição que afeta todas as
            empresas em nosso país. A grande maioria dos custos de armazenagem –
            aluguel de galpão, mão-de-obra, depreciação de instalações e
            equipamentos de movimentação dificultam respectivamente o
            gerenciamento da operação. <br></br> <br></br> O custo de
            armazenagem pode ter um grande impacto na cadeia logística da sua
            empresa, pois afeta toda gestão e compromete a operação de
            recebimento, estocagem e expedição. Manter um armazém em
            funcionamento envolve despesas associadas de vários tipos, exige uma
            gestão eficiente das operações, a integração com o transporte e
            adequar os níveis de estoque ao estritamente necessário. <br></br> 
            <br></br>
            De acordo com um estudo feito pela Custos Logísticos no Brasil, do
            Ilos (Instituto de Logística e Supply Chain), o custo logístico
            consome 12,7% do PIB (Produto Interno Bruto) do Brasil, que
            corresponde ao total das riquezas produzidas pelo país. A maior
            parte do custo é formada pelo transporte, que equivale a 6,8% do PIB
            (R$ 401 bilhões). Depois vem estoque (4,5% do PIB, ou R$ 268
            bilhões); armazenagem (0,9% do PIB ou R$ 53 bilhões); e
            administrativo (0,5% do PIB, ou R$ 27 bilhões). <br></br> <br></br>{" "}
            Para otimização da cadeia logística, a maioria das empresas, de
            pequeno a grande porte, têm visto uma boa oportunidade na
            terceirização da armazenagem. Entenda como a terceirização de
            estoque vai reduzir custos da sua empresa. Nos últimos anos, buscar
            eficiência na armazenagem se tornou essencial para o funcionamento
            de qualquer negócio. Está dentro do planejamento logístico o local
            que servirá como estoque dos seus produtos e que reunirá os seus
            maiores custos. Podemos dividir em: 
            <br></br>
            <br></br>
            1. Impostos;<br></br> 2.Energia elétrica;<br></br> 3. Manutenção (ou
            aluguel caso o armazém seja alugado); <br></br> 4. Equipamentos de
            movimentação e armazenagem, como paleteiras, empilhadeiras, porta
            paletes, prateleiras;<br></br> 5. Salários e encargos dos
            funcionários.<br></br>  <br></br>O nosso sistema de armazenagem
            envolve seis partes num galpão logístico de 7.000m². Ao terceirizar
            a armazenagem, a sua empresa não precisará investir em equipamentos
            ou na aquisição de um novo imóvel, ou ainda na ampliação de sua sede
            para que tenha a estrutura que um armazém exige para um bom
            funcionamento.<br></br>
            <br></br>Controle das Informações: tecnologia especializada e
            softwares de gestão dos estoques Outra vantagem com a terceirização
            é a economia em relação a softwares que fazem a gestão dos estoques.
            Com moderno sistema de controle de estoques que realiza toda gestão
            necessária, desde entradas e saídas de mercadorias, níveis de
            estoque até a realização de inventários.
            <br></br>
            <br></br>Responsáveis por administrar e aprimorar a disposição e
            alocação de materiais no armazém, os sistemas do tipo Warehouse
            Management System (Sistema de Gerenciamento de Armazém), chamados
            WMS, são muito importantes para o funcionamento da cadeia de
            suprimentos.
          </CCardText>
          <CCardText>
            <small className="text-visible">170 visualizações</small>
          </CCardText>
        </CCardBody>
      </CCard>
    </div>
  );
}

export default SectionVisiblePubli;
