import React from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CButton,
} from "@coreui/react-pro";
import { useHomeContext } from "../../view/home/contexts/HomeContext";

function ModalDelete() {

  const {selectedSubscriber, hideModal, isUnsubscribeLoading, handleUnsubscribe} = useHomeContext()

  const isModalVisible = selectedSubscriber !== null && selectedSubscriber?.modal === 'unsubscribe'

  return (
    <CModal 
      id="modal" 
      size="xl" 
      visible={isModalVisible} 
      backdrop={'static'}
      onClose={ hideModal }
      >
      <CModalHeader>
        <CModalTitle id="OptionalSizesExample1"></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="title-modal-delete">Confirmação de desativação de envio de e-mail</div>
        <div className="mt-5">
          <h3 className="delete">Você gostaria de desativar o envio de e-mails para este endereço de e-mail?</h3>
          <p className="delete">{selectedSubscriber?.email}</p>
        </div>
        <div className="d-flex gap-3">
          <CButton onClick={hideModal} disabled={isUnsubscribeLoading} className="button-modal-cancel mt-5 mb-3">
            Cancelar
          </CButton>
          <CButton onClick={() => handleUnsubscribe(selectedSubscriber?.id)} disabled={isUnsubscribeLoading} className="button-modal mt-5 mb-3">
            {isUnsubscribeLoading ? 'Desativando...' : 'Confirmar'}
          </CButton>
        </div>
      </CModalBody>
    </CModal>
  );
}

export default ModalDelete;
