import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./view/home/home";
import VisiblePubli from "./view/blog/visiblePubli";
import LoginBlog from "./view/login/loginBlog";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    path: "/",
    children: [
      {
        path: "*",
        element: <LoginBlog />,
        index: true,
      },
      {
        element: <Home />,
        index: true,
      },
      {
        path: "/visiblePubli",
        element: <VisiblePubli />,
      },
    ],
  },
]);
