import { generateClientErrorMessage } from "../utils/generateClientErrorMessage"
import { api } from "../config/api"

const getAllSubscribers = async (limit = 5, offset = 0) => {
    try {
        const res = await api.get(`/getAllSubscribers?limit=${limit}&offset=${offset}`);

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }
        
        return { success: true, data: res.data }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}



const unsubscribeById = async (subcriberId) => {
    try {
        const res = await api.delete(`/unsubscribeById/${subcriberId}`)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, message: res.data.message }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}


const sendLastPostToNewsletterSubscribers = async () => {
    try {
        const res = await api.post(`/sendLastPostToNewsletterSubscribers`)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, message: res.data.message }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}





export default {
    getAllSubscribers,
    unsubscribeById,
    sendLastPostToNewsletterSubscribers
}