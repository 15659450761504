import React from 'react'
import style from '../assets/scss/components/spinner.module.scss'

const LoadingSpinner = () => {
  return (

    <div className={style.spinner}></div>

  )
}

export default LoadingSpinner