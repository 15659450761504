import Navbar from "../../components/Navbar";
import SubNavBlog from "../../components/SubNavBlog";
import Footer from "../../components/Footer";
import SwiperCard from "./components/SwiperCard";
import SectionVisiblePubli from "./components/SectionVisiblePubli";

function VisiblePubli() {
  return (
    <>
      <Navbar />
      <SubNavBlog />
      <SectionVisiblePubli></SectionVisiblePubli>
      <h1 className="tittle-visible-publi container">Posts recentes</h1>
      <SwiperCard></SwiperCard>
      <Footer />
    </>
  );
}

export default VisiblePubli;
