import { generateClientErrorMessage } from "../utils/generateClientErrorMessage"
import { api } from "../config/api"


const createPost = async (post) => {
    try {

        const res = await api.post('/createPost', { ...post, id_user: localStorage.getItem('id_user') })

        const responseStatus = res.status ?? res.response.status 

        const success = responseStatus === 200

        const errorMessage = responseStatus === 413 ? 'Imagens inseridas na descrição da  publicação são muito pesadas' : res.status

        return {
            success,
            data: res.data ?? null,
            message: success ? res.data.message : errorMessage
        }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}


const uploadPostImage = async ({ id_post, rawFile }) => {
    try {


        const file = new FormData()
        file.append('file', rawFile)

        const res = await api.post(`/uploadImage/${id_post}`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, message: res.data.message }

    } catch (error) {

        console.log('\n\n erro de upload\n', error)

        return { success: false, message: generateClientErrorMessage(error) }
    }
}

const getPostImage = async (path) => {
    try {
        const res = await api.get(`/getImage?path=${path}`, {
            responseType: 'blob'
        })

        let file = null

        if (!res.data) {//caso dê erro, retorna o html de 404
            file = new Blob([res.response.data], { type: res.response.data.type })
        } else {
            file = new Blob([res.data], { type: res.data.type })
        }

        const url = URL.createObjectURL(file)

        return { success: true, data: { url, file } }


    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

const getAllPosts = async (path) => {
    try {
        const res = await api.get(`/getAllPosts`)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, data: res.data.posts }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

const getPostById = async (id) => {
    try {
        const res = await api.get(`/getPostById?id=${id}`);

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, data: res.data.posts }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}



const deletePost = async (postId) => {
    try {
        const res = await api.delete(`/deletePost/${postId}`)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, data: res.data.posts }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}


const updatePost = async (post) => {
    try {
        const res = await api.patch(`/updatePost`, post)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, message: res.data.message }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

export default {
    createPost,
    uploadPostImage,
    getPostImage,
    getAllPosts,
    deletePost,
    getPostById,
    updatePost
}