import React, { useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCardImage,
} from "@coreui/react-pro";
import { useAlert } from "../../contexts/AlertContext";
import postServices from '../../services/post'
import newsletterServices from '../../services/newsletter'

import { useMutation, useQueryClient } from "@tanstack/react-query";
import CustomReactQuill from "../CustomReactQuill";

function CreatePostModal({ visible, closeModal, hideModal }) {

  const { hideAlert, showAlert } = useAlert()

  const defaultPostValue = {
    image: { file: null, url: '' },
    title: '',
    description: '',
  }

  const [post, setPost] = useState(defaultPostValue)


  const maxSizeInBytes = 2 * 1024 * 1024 // 2 MB

  const handleFileChange = (e) => {

    const file = e.target.files[0]
    const exceedsSizeLimit = file.size > maxSizeInBytes

    if (!exceedsSizeLimit) {
      setPost(prev => ({ ...prev, image: { url: URL.createObjectURL(file), file } }))
      return
    }

    showAlert('Por favor, selecione um arquivo PDF, PNG, JPEG ou JPG com até 2 MB.', 'error')

  };
  

  const queryClient = useQueryClient()

  const { mutate: handleCreatePost, isPending: isCreateLoading } = useMutation({
    mutationFn: async () => {

      hideAlert()

      if (!post.image.file) {
        return { success: false, message: 'Por favor, selecione um arquivo PDF, PNG, JPEG ou JPG com até 2 MB.' }
      }

      const postResponse = await postServices.createPost({
        ...post,
      })

      if (!postResponse.success) {
        return postResponse
      }

      const uploadResponse = await postServices.uploadPostImage({
        id_post: postResponse.data.id,
        rawFile: post.image.file
      })

      if (!postResponse.success) {
        showAlert(uploadResponse.message, 'warning')
      }


      return postResponse
    },
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries(['carrosel'])
        closeModal()
        newsletterServices.sendLastPostToNewsletterSubscribers()
      }
      showAlert(response.message, response.success ? 'success' : 'error')
    },
    onError: (response) => showAlert(response.message, 'error')
  })



  return (
    <CModal
      id="modal"
      backdrop="static"
      size="xl"
      visible={visible}
      onClose={() => {
        setPost(defaultPostValue);
        closeModal();
      }}
      aria-labelledby="OptionalSizesExample1"
    >
      <CModalHeader>
        <CModalTitle id="OptionalSizesExample1"></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="title-modal">Criar nova publicação</div>
        <CForm>
          <div className="mt-5 input-img">
            <CFormLabel>Imagem:</CFormLabel>
            {post.image.file && (
              <>
                <div className="postImageContainer">
                  <CCardImage orientation="top" src={post.image.url} />
                </div>
                <p>{post.image.file.name}</p>
              </>
            )}
            <CFormInput
              type="file"
              accept=".png,.jpeg,.jpg,.webp"
              maxLength="50"
              floatingClassName="mb-3"
              onChange={handleFileChange}
            />
          </div>
          <CFormLabel className="mt-4">Titulo:</CFormLabel>
          <CFormInput type="text"
            value={post.title}
            onChange={e => setPost(prev => ({ ...prev, title: e.target.value }))}
          />


          <CFormLabel className="mt-4">Descrição:</CFormLabel>
          <CustomReactQuill
            value={post.description}
            onChange={e => {
              setPost(prev => ({ ...prev, description: e }))
            }}
          />
          <CButton
            className="button-modal mt-5 mb-3"
            disabled={isCreateLoading}
            onClick={handleCreatePost}
          >
            {isCreateLoading ? 'Publicando...' : 'Publicar'}
          </CButton>
        </CForm>
      </CModalBody>
    </CModal>
  );
}

export default CreatePostModal;
