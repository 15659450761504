import React, { useEffect } from 'react'
import { Outlet } from "react-router-dom";
import useRedirectAfterUnhautorizedRequest from './hooks/useRedirectAfterUnhautorizedRequest';
import user from './services/user';

const Layout = () => {

    //na primeira renderização do app: se nao estiver na page de login, vai enviar uma request pra verificar se o token no localStorage é valido
    //se nao, o hook useRedirectAfterUnhautorizedRequest captura o erro 401 
    useEffect(() => {
        if (!window.location.href.includes('login')) {
            user.checkAuth()
        }
    }, [])

    //recepta todas as requisições do axios e redireciona para /login qaundo alguma response tem status 401
    useRedirectAfterUnhautorizedRequest()

    return (
        <div>
            <Outlet />
        </div>
    )
}

export default Layout