import "react-quill/dist/quill.snow.css";
import React from 'react'
import ReactQuill from 'react-quill';
import style from '../assets/scss/components/reactquill.module.scss'

const CustomReactQuill = ({ ...rest }) => {

    //add autoplay e mute no iframe de video pra permitir autoplay
    const Video = ReactQuill.Quill.import('formats/video');
    Video.sanitize = (url) => {
        if (url.includes('youtube.com')) {
            const urlObj = new URL(url);
            urlObj.searchParams.set('autoplay', '1');
            urlObj.searchParams.set('mute', '1');
            return urlObj.toString();
        }
        return url;
    };


    return (
        <ReactQuill
            {...rest}
            className={`text-quill ${style.quill}`}
            theme="snow"
            modules={{
                toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    ["clean"],
                ],
            }}
        />
    )
}

export default React.memo(CustomReactQuill)
