import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useState } from "react";
import newsletterServices from '../../../services/newsletter'
import { useAlert } from "../../../contexts/AlertContext";

const HomeContext = createContext()

export const useHomeContext = () => useContext(HomeContext)


export const HomeProvider = ({ children }) => {


    const defaultPaginationData = {
        itemsToShow: 5,
        currentPage: 1,
    };

    const { showAlert } = useAlert();


    const [pagination, setPagination] = useState(defaultPaginationData);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null)

    const hideModal = () => setSelectedSubscriber(null)

    const { data: tableResponse, isLoading: isTableLoading } = useQuery({
        queryKey: [
            "newsletter-table",
            pagination.itemsToShow,
            pagination.currentPage,
        ],
        queryFn: () =>
            newsletterServices.getAllSubscribers(
                pagination.itemsToShow,
                pagination.itemsToShow * (pagination.currentPage - 1)
            ),
    });

    const queryClient = useQueryClient();

    const { mutate: handleUnsubscribe, isUnsubscribeLoading } = useMutation({
        mutationFn: (id) => newsletterServices.unsubscribeById(id),
        onSuccess: (response) => {
            if (response.success) {
                queryClient.invalidateQueries(["newsletter-table"]);
                hideModal()
            }
            showAlert(response.message, response.success ? "success" : "error");
        },
        onError: (response) => showAlert(response.message, "error"),
    });




    const contextValues = {
        tableResponse,
        isTableLoading,
        handleUnsubscribe,
        isUnsubscribeLoading,
        setPagination,
        pagination,
        selectedSubscriber,
        setSelectedSubscriber,
        hideModal
    }


    return (
        <HomeContext.Provider value={contextValues}>
            {children}
        </HomeContext.Provider>
    )
}