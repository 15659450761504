import { useNavigate } from 'react-router-dom'
import { useAlert } from '../contexts/AlertContext'

const useRedirectAfterUnhautorizedRequest = () => {

    const navigate = useNavigate()
    const { showAlert } = useAlert()

    const handleSessionStorageChange = (event) => {
        showAlert('Sessão expirada. Faça login novamente', 'error')
        navigate('/login')
    }

    window.addEventListener('authError', handleSessionStorageChange)

    return () => {
        window.removeEventListener(handleSessionStorageChange)
    }
}

export default useRedirectAfterUnhautorizedRequest