import React, { useState, useEffect } from "react";
import {
  CNavbar,
  CNavbarBrand,
  CContainer,
  CButton,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilSearch } from "@coreui/icons";

function SubNavBlog() {
  return (
    <div id="sub-nav">
      <CNavbar colorScheme="light">
        <CForm>
          <CNavbarBrand href="#">Todos os posts</CNavbarBrand>
          <div className="d-flex ms-4">
            <CFormInput
              type="search"
              className="me-2"
              placeholder="Pesquisar"
            />
            <CInputGroupText id="basic-addon1">
              <CIcon icon={cilSearch} width={20} />
            </CInputGroupText>
          </div>
          <a href="/loginBlog" target="_blank" rel="noopener noreferrer">
            <CButton>Entrar</CButton>
          </a>
        </CForm>
      </CNavbar>
    </div>
  );
}

export default SubNavBlog;
