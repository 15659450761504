import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import CardPublicationSmall from "./CardPublicationSmall";
import postServices from '../../../services/post'
import arrowRight from "../../../assets/img/swiper/arrow-right.webp";
import arrowLeft from "../../../assets/img/swiper/arrow-left.webp";
import CIcon from "@coreui/icons-react";
import { cilArrowCircleLeft, cilArrowCircleRight } from "@coreui/icons";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../../components/LoadingSpinner";

function SwiperCard() {


  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const breakpoints = {
    1600: {
      slidesPerView: 4,
    },
    991: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
    600: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  };

  const { isPending, data, isError } = useQuery({
    queryKey: ['carrosel'],
    queryFn: () => postServices.getAllPosts(),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })

  return (
    <div id="card-publication-sm">
        <div className="carousel-wrapper">
          {isPending ? (<LoadingSpinner />) : (<>
            <Swiper
              loop={true}
              slidesPerView={3}
              spaceBetween={10}
              breakpoints={breakpoints}
              pagination={{
                dynamicBullets: true,
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              <div className={`swiper-button-next`}><img src={arrowRight} width={'35px'} alt="" /></div>
              {data?.data?.map((post, index) => (
                <SwiperSlide key={index}>
                  <CardPublicationSmall post={{ ...post, index }} />
                </SwiperSlide>
              ))}
              <div className={`swiper-button-prev`}><img src={arrowLeft} width={'35px'} alt="" /></div>
            </Swiper>

          </>)}
        </div>
        <div className="gradient-white"></div>
      </div>
  );
}

export default SwiperCard;
