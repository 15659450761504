import React, { useState } from "react";

import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CSmartPagination,
  CFormSelect,
} from "@coreui/react-pro";

import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import style from "./table.module.scss";
import LoadingSpinner from "../LoadingSpinner";
import { useHomeContext } from "../../view/home/contexts/HomeContext";

function TableNewlatter() {

  const {
    tableResponse,
    isTableLoading,
    isUnsubscribeLoading,
    setPagination,
    pagination,
    setSelectedSubscriber
  } = useHomeContext()

  return (
    <>
      <div className="container table-border py-4 px-4">
        <div>
          <div className=" align-center py-2">
            <CFormSelect
              className={style.selectItemsToShow}
              options={[
                { label: "5", value: "5" },
                { label: "10", value: "10" },
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "100", value: "100" },
              ]}
              value={pagination.itemsToShow}
              onChange={(evt) => {
                setPagination((prev) => ({
                  ...prev,
                  itemsToShow: evt.target.value,
                }));
              }}
            />
          </div>
        </div>

        {isTableLoading ? (
          <LoadingSpinner/>
        ) : (
          <>
            <CTable striped hover>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Ferramentas</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {tableResponse?.data?.subscribers.map((subcriber, index) => {
                  return (
                    <React.Fragment key={subcriber.email + index}>
                      <CTableRow>
                        <CTableDataCell>{subcriber.email}</CTableDataCell>
                        <CTableDataCell>
                          <button
                            type="submit"
                            className="button-table-red py-2 px-2 mx-2"
                            title="Desfazer inscrição"
                            disabled={isUnsubscribeLoading}
                            onClick={() => setSelectedSubscriber({...subcriber, modal: 'unsubscribe'})}
                          >
                            <CIcon
                              icon={icon.cilTrash}
                              size="lg"
                              style={{ "--ci-primary-color": "#fff" }}
                            />
                          </button>
                        </CTableDataCell>
                      </CTableRow>
                    </React.Fragment>
                  );
                })}
              </CTableBody>
            </CTable>
            <label className="text-gray">
              Mostrando de{" "}
              {Math.min(
                (pagination.currentPage - 1) * pagination.itemsToShow + 1,
                tableResponse?.data?.count
              )}{" "}
              até{" "}
              {Math.min(
                pagination.currentPage * pagination.itemsToShow,
                tableResponse?.data?.count
              )}{" "}
              de {tableResponse?.data?.count} emails cadastrados
            </label>
            <CSmartPagination
              size="sm"
              align="end"
              activePage={pagination.currentPage}
              pages={Math.ceil(
                tableResponse?.data?.count / pagination.itemsToShow
              )}
              onActivePageChange={(pageNumber) =>
                setPagination((prev) => ({ ...prev, currentPage: pageNumber }))
              }
            />
          </>
        )}
      </div>

    </>
  );
}

export default React.memo(TableNewlatter);
